exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-routes-contact-contact-jsx": () => import("./../../../src/routes/Contact/Contact.jsx" /* webpackChunkName: "component---src-routes-contact-contact-jsx" */),
  "component---src-routes-home-home-jsx": () => import("./../../../src/routes/Home/Home.jsx" /* webpackChunkName: "component---src-routes-home-home-jsx" */),
  "component---src-routes-prices-prices-jsx": () => import("./../../../src/routes/Prices/Prices.jsx" /* webpackChunkName: "component---src-routes-prices-prices-jsx" */),
  "component---src-routes-privacy-policy-privacy-policy-jsx": () => import("./../../../src/routes/PrivacyPolicy/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-routes-privacy-policy-privacy-policy-jsx" */),
  "component---src-routes-procedure-procedure-jsx": () => import("./../../../src/routes/Procedure/Procedure.jsx" /* webpackChunkName: "component---src-routes-procedure-procedure-jsx" */),
  "component---src-routes-team-team-jsx": () => import("./../../../src/routes/Team/Team.jsx" /* webpackChunkName: "component---src-routes-team-team-jsx" */),
  "component---src-routes-therapies-therapies-jsx": () => import("./../../../src/routes/Therapies/Therapies.jsx" /* webpackChunkName: "component---src-routes-therapies-therapies-jsx" */)
}

