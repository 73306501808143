import { createTheme as createMuiTheme } from '@material-ui/core/styles';

export const createTheme = (breakpoints, palette) => createMuiTheme({
  breakpoints: {
    values: breakpoints,
  },
  overrides: {
    MuiButton: {
      root: {
        '& a:visited': {
          color: 'inherit',
        },
        borderRadius: 32,
        fontWeight: 400,
      },
    },
  },
  palette,
  typography: {
    fontFamily: [
      'AvenirNext',
      'Kumbh Sans',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      [`@media (min-width: ${breakpoints.md}px)`]: {
        fontSize: '3.6rem',
      },
      [`@media (min-width: ${breakpoints.lg}px)`]: {
        fontSize: '4.0rem',
      },
      [`@media (min-width: ${breakpoints.xl}px)`]: {
        fontSize: '4.4rem',
      },
      color: palette.primary.dark,
      fontSize: '2.7rem',
      fontWeight: 400,
      letterSpacing: '-0.05em',
      lineHeight: '130%',
      margin: 0,
      padding: 0,
    },
    h2: {
      [`@media (min-width: ${breakpoints.md}px)`]: {
        fontSize: '3.0rem',
      },
      color: palette.primary.dark,
      fontSize: '2.5rem',
      fontWeight: 400,
      letterSpacing: '-0.04em',
      lineHeight: '130%',
      margin: 0,
      padding: 0,
    },
    h3: {
      [`@media (min-width: ${breakpoints.md}px)`]: {
        fontSize: '2.8rem',
      },
      color: palette.primary.dark,
      fontSize: '2.3rem',
      fontWeight: 400,
      letterSpacing: '-0.04em',
      lineHeight: '130%',
      margin: 0,
      padding: 0,
    },
    h4: {
      [`@media (min-width: ${breakpoints.md}px)`]: {
        fontSize: '1.6rem',
      },
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '130%',
      margin: 0,
      padding: 0,
    },
    h5: {
      [`@media (min-width: ${breakpoints.md}px)`]: {
        fontSize: '1.4rem',
      },
      fontSize: '1.2rem',
      lineHeight: '130%',
      margin: 0,
      padding: 0,
    },
    useNextVariants: true,
  },
});

