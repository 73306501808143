import red from '@material-ui/core/colors/red';

export const lightPalette = {
  error: red,
  primary: {
    contrastText: '#fff',
    dark: '#5d6970',
    light: '#c7d6db',
    main: '#6c767a',
  },
  secondary: {
    contrastText: '#fff',
    dark: '#785f43',
    light: '#fcf2e7',
    main: '#a88c6e',
  },
  type: 'light',
};
