import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import translationES from './assets/locales/es/actpsicologos-app.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
      formatSeparator: ',',
    },
    lng: 'es',
    react: {
      wait: true,
    },
    resources: {
      es: {
        translation: translationES,
      },
    },
    returnObjects: true,
  });

export default i18n;
