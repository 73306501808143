// https://www.gatsbyjs.com/docs/browser-apis/
require('./src/styles.css');
require('./src/i18n');

const { ThemeProvider } = require('@material-ui/core/styles');
const { createTheme } = require('./src/assets/jss/actpsicologos-app');
const { breakpoints } = require('./src/assets/jss/breakpoints');
const { lightPalette } = require('./src/assets/jss/palette');

const theme = createTheme(breakpoints, lightPalette);

exports.wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    {element}
  </ThemeProvider>
);
